import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'
import styled from 'styled-components'

// Images
import ButtonDefault from 'components/elements/ButtonDefault'
import ContentDefault from 'components/elements/ContentDefault'
import arrowUp from 'img/arrow-up.svg'

import iconDownload from 'img/icons/icon-download.svg'

import GravityForm from 'components/GravityForm'

const customStyles = {
  overlay: {
    zIndex: 9,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    borderRadius: '20px',
  },
}

const Button = styled.div`
  background-color: ${props => props.theme.color.contrast};
  display: inline-block;
  transition: transform 0.25s;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @media (max-width: 991px){
    padding: 10px 0;
  }

  &:hover {
    transform: scale(1.05);
  }

  & > span {
    font-family: ${props => props.theme.font.family.secondaryAlt};
    font-size: ${props => props.theme.font.size.m};
    color: ${props => props.theme.color.text.light} !important;
    padding: 10px 15px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  @media (min-width: 992px){
    & img {
      margin-right: 15px;
    }
  }
`

const ModalInner = styled.div`
    width: 500px;
    height: 500px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-top: 10px solid ${props => props.theme.color.contrast};

    @media (max-width: 991px){
      width: 350px;
    }

    & form {
        width: 80%;
    }
`

const Content = styled(ContentDefault)`
  & p.quote {
    background-color: ${props => props.theme.color.contrast};
    color: #fff;
    text-align: center;
    padding: 10px 0;
    position: relative;
    margin-bottom: 0;
    font-weight: 700;

    @media (max-width: 991px){
      line-height: 20px;
    }

    &::before {
      content: '';
      background: url(${arrowUp}) no-repeat;
      display: block;
      position: absolute;
      width: 24px;
      height: 24px;
      top: -10px;
      left: 50%;
      margin-left: -12px;

      @media (max-width: 991px){
        top: -43px;
      }
    }
  }
`

const Title = styled.h2`
font-family: ${props => props.theme.font.family.secondary};
    font-size: ${props => props.theme.font.size.xm};
    text-align: center;

    @media (min-width: 992px){
        margin-bottom: 10px;
    }
`
const ButtonDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0 20px;
`

function App({ children, buttonText }) {
  const [modalIsOpen, setIsOpen] = useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <div className="ervaring">
      <Button onClick={openModal} type="button">
        <img src={iconDownload} width="20" />
        <Content content={buttonText} />
      </Button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <ModalInner>
          <ButtonDiv><button onClick={closeModal}>X</button></ButtonDiv>
            <Title>Download onze tips: Als starter een hypotheek afsluiten? Dit moet je weten</Title>
            <GravityForm id={5} />
       </ModalInner>
      </Modal>
    </div>
  )
}

export default App
