/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Images
import phone from 'img/phone.svg'


// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import HeroDefault from 'components/elements/HeroDefault'
import TitleDefault from 'components/elements/TitleDefault'
import ContentDefault from 'components/elements/ContentDefault'
import ButtonDefault from 'components/elements/ButtonDefault'
import CustomLink from 'components/shared/CustomLink'
import Testimonials from 'components/Testimonials'
import GravityForm from 'components/GravityForm'
import DownloadModal from 'components/elements/DownloadModal'


// Images
import ChevronRight from 'img/chevron-right.svg'

const Container = styled.div`
  position: relative;  

  & h1 {
    font-size: 60px;
  }
`
const Service = styled.div`
  background-color: ${props => props.theme.color.light};
  box-shadow: 0px 0px 4px #00000080;
  border-bottom-left-radius: 20px;
  transition: transform 0.25s;

  &:hover {
    transform: scale(1.05);
  }

  @media (min-width: 991px){
    max-width: 319px;
  }
  
`
const ServiceTitle = styled.h3`
  background-color: ${props => props.theme.color.grey};
  font-family: ${props => props.theme.font.family.secondaryAlt};
  font-size: ${props => props.theme.font.size.xm};
  color: ${props => props.theme.color.text.secondary};
`

const ServiceImage = styled.div`
  position: relative;
`

const Contact = styled.div``

const FormContainer = styled.div`
  box-shadow: 0px 0px 4px #00000080;
  max-width: 600px;
  border-bottom-left-radius: 20px;
`

const FormTitle = styled.div`
  height: 50px;
  max-width: 600px;
  padding-left: 100px;
  padding-right: 100px;

  h2 {
    font-size: ${props => props.theme.font.size.ml};

    @media screen and (max-width: 991px) {
      font-size: ${props => props.theme.font.size.xm};
    }
  }

  @media screen and (max-width: 991px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`

const FormWrapper = styled.div`
  padding-left: 100px;
  padding-right: 100px;

  @media screen and (max-width: 991px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`

const Info = styled.div`
  width: 320px;

  @media (max-width: 991px){
    width: 560px;
  }

  a {
    color: ${props => props.theme.color.text.light};
    &:hover {
      text-decoration: underline;
    }
  }
`

const Icon = styled.img`
  width: 23px;
  height: 23px;
`

const StyledButtonDefault = styled(ButtonDefault)`
  transition: transform 0.25s;

  &:hover {
    transform: scale(1.05);
  }
`

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      acf {
        bannerImage: banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              meta: fluid(maxWidth: 800, toFormat: JPG) {
                src
              }
            }
          }
        }
        bannerTitle: banner_title
        bannerButtonText: banner_button_text
        bannerButtonLink: banner_button_link

        intro {
          title
          description
          button {
            text
            url
          }
        }

        formSection: form_section {
          title
          ctaTitle: cta_title
        }

        testimonial {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          quote
        }
      }
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }

    contact: wordpressPage(wordpress_id: { eq: 9 }) {
      acf {
        phonenumber
        email_address
        streetname
        housenumber
        zipcode
        city
      }
    }

    services: allWordpressPage(filter: { wordpress_parent: { eq: 20 } }) {
      edges {
        node {
          acf {
            excerpt {
              title
              icon {
                localFile {
                  publicURL
                }
              }
            }
          }
          path
          wpId: wordpress_id
        }
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, title, yoastMeta, acf },
    services: { edges: services },
    contact,
  },
}) => {
  return (
    <Layout>
      <SEO
        yoast={{ meta: yoastMeta }}
        pathname={path}
        image={acf.bannerImage.localFile.childImageSharp.fluid.src}
        article
      />
     <HeroDefault
        image={acf.bannerImage}
        title={acf.bannerTitle}
        linkWithIcon={{
          link: {
            text: acf.bannerButtonText,
            url: acf.bannerButtonLink,
          },
        }}
      />
      <section>
        <Container className="container pt-5">
        <div className="row pt-5">
            <div className="col-lg-6">
              <TitleDefault h1 className="text-break">
                {acf.intro.title}
              </TitleDefault>
            </div>
            <div className="col-lg-6">
              <ContentDefault content={acf.intro.description} />
              <ButtonDefault to={acf.intro.button.url}>{acf.intro.button.text}</ButtonDefault>
            </div>
          </div>
        </Container>
      </section>
      <section className="mb-5">
        <Container className="container py-lg-4">
          <div className="row">
            {services.map(({ node }) => (
              <div className="col-md-4">
                <Service className="mb-5" key={node.wpId}>
                  <CustomLink to={node.path}>
                    <ServiceTitle className="text-right pr-4 py-2">{node.acf.excerpt.title}</ServiceTitle>
                    <ServiceImage className="d-flex justify-content-between align-items-end py-4 px-3">
                      <img src={ChevronRight} alt="" />
                      <img src={node.acf.excerpt.icon.localFile.publicURL} alt="" />
                    </ServiceImage>
                  </CustomLink>
                </Service>
              </div>
            ))}
          </div>
        </Container>
      </section>

      <Contact id="contact" className="color-background-soft py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 d-lg-block d-flex justify-content-center py-lg-0 py-4e">
              <FormContainer className="d-flex justify-content-center flex-wrap color-background-light">
                <FormTitle className="w-100 d-flex align-items-center color-background-contrast">
                  <h2 className="mb-0 font-family-secondary-alt color-text-light">{acf.formSection.title}</h2>
                </FormTitle>
                <FormWrapper className="w-100 pb-5 pt-4">
                  <GravityForm id={1} />
                </FormWrapper>
              </FormContainer>
            </div>
            <div className="col-lg-4 d-lg-block d-flex justify-content-center py-lg-0 py-4">
              <Info className="color-background-dark px-5 pt-4 pb-5">
                <h2 className="mb-0 mt-2 font-size-xm font-family-secondary-alt color-text-light">{acf.formSection.ctaTitle}</h2>

                <div className="d-flex justify-content-center py-4">
                  <StyledButtonDefault>
                    <Icon className="mr-2" src={phone} alt="" />
                    <p className="font-size-m font-family-secondary-alt mb-0"><a href={`tel:${contact.acf.phonenumber}`}>{contact.acf.phonenumber}</a></p>
                  </StyledButtonDefault>
                </div>

                <h2 className="font-size-xsm color-text-light font-family-secondary-alt">WelBijWim</h2>
                <p className="font-size-sm color-text-light mb-0">{`${contact.acf.streetname} ${contact.acf.housenumber}`}</p>
                <p className="font-size-sm color-text-light mb-0">{contact.acf.zipcode}</p>
                <p className="font-size-sm color-text-light mb-3">{contact.acf.city}</p>
                <a href={`mailto:${contact.acf.email}`} className="font-size-sm color-text-light">{contact.acf.email}</a>
              </Info>
            </div>
          </div>
        </div>
      </Contact>

      <section>
        <Testimonials id={[acf.testimonial.quote]} image={acf.testimonial.image.localFile.childImageSharp.fluid} />
      </section>
    </Layout>
  )
}

export default PageTemplate
